<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">基本信息</a-divider>
			<a-descriptions :column="2">
				<a-descriptions-item label="经销商编码">
					{{ detail.sellerId||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="订货单号">
					{{ detail.orderId }}
				</a-descriptions-item>
				<a-descriptions-item label="日期">
					{{ detail.outcomeTime }}
				</a-descriptions-item>
				<a-descriptions-item label="出库单号">
					{{ detail.outcomeId||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="出库单类型">
					{{ detail.emptyBarrelInfo ? '桶装' : '瓶装' }}
				</a-descriptions-item>
				<a-descriptions-item label="出库状态">
					已出库
				</a-descriptions-item>
				<a-descriptions-item label="出库单通知">
					{{ detail.notify_status==0?'未推送':'已推送' }}
				</a-descriptions-item>
                <a-descriptions-item label="服务区域">
                	{{detail.department}}
                </a-descriptions-item>
			</a-descriptions>
			<!-- <a-descriptions :column="1">
				<a-descriptions-item label="签名">
					<img style="width:100px;height:auto" :src="detail.sign_url">
				</a-descriptions-item>
			</a-descriptions> -->
			<div style="display:flex;margin-bottom: 5px;">
				<div style="color:#000000">签字：</div>
				<div>
					<img style="width:100px;height:auto" :src="detail.sign_url">
				</div>
			</div>
            <div class="li_item" >
            	<p style="margin-bottom:5px">阅读状态：
                <span v-if="detail.is_read==0">全部未读</span>
                 <span v-if="detail.is_read==1">部分已读</span>
                  <span v-if="detail.is_read==2">全部已读</span>
                </p>
            	<div class="arpproval_title " >
            		<span class="department_appr">姓名</span>
            		<span class="department_appr">电话</span>
            		<span class="department_appr">角色</span>
            		<span class="department_appr">部门</span>
            		<span class="department_appr">阅读时间</span>
            	</div>
            	<div  class="arpproval_title arpproval_title2" v-for="item in detail.notify_record">
            		<span class="department_appr">{{item.name||''}}</span>
            		<span class="department_appr">{{item.phone||''}}</span>
            		<span class="department_appr">{{item.role_name||''}}</span>
            		<span class="department_appr"> {{item.department||''}}</span>
            		<span class="department_appr">{{item.read_time}}</span>
            	</div>
			</div>
            
			<a-divider orientation="left">经销商信息</a-divider>
			<a-descriptions :column="2">
				<a-descriptions-item label="经销商可用余额">
					{{detail.sellerAvailableMoney.toLocaleString() ||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="经销商对应主管">
					{{ detail.name||'--' }}
				</a-descriptions-item>
				<!-- <a-descriptions-item label="经销商可用余额" v-if='detail.order_price'>
				    {{ detail.order_price }}
				</a-descriptions-item> -->
			</a-descriptions>
			<div class="li_item"  v-show='detail.emptyBarrelInfo'>
				<p>经销商空桶库存：{{detail.emptyBarrelInfo.availableCreditCount}}</p>
				<div class="arpproval_title arpproval_title1">
					<span class="department_appr">空桶信用额度</span>
					<span class="department_appr">{{detail.emptyBarrelInfo.creditCount}}</span>
				</div>
				<div class="arpproval_title arpproval_title1">
					<span class="department_appr">空桶可用额度</span>
					<span class="department_appr">{{detail.emptyBarrelInfo.availableCount}}</span>
				</div>
				<div class="arpproval_title arpproval_title1">
					<span class="department_appr">拖欠空桶数量</span>
					<span class="department_appr">{{detail.emptyBarrelInfo.borrowCount}}</span>
				</div>
				<div class="arpproval_title arpproval_title1">
					<span class="department_appr">可用空桶数量</span>
					<span class="department_appr">{{detail.emptyBarrelInfo.availableCreditCount}}</span>
				</div>

			</div>

			<a-divider orientation="left">收货信息</a-divider>
			<a-descriptions :column="2">
				<a-descriptions-item label="收货人地址">
					{{ detail.receiveAddress ||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="收货人">
					{{ detail.consignee||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="收货人电话">
					{{ detail.sellerPhone }}
				</a-descriptions-item>
			</a-descriptions>
			<a-divider orientation="left">出库信息</a-divider>
			<a-descriptions :column="2">
			    <a-descriptions-item label="销售区域">
			        {{ detail.organizationName||'--'}}
			    </a-descriptions-item>
			    <a-descriptions-item label="发货申请人">
			        {{ detail.consignee||'--' }}
			    </a-descriptions-item>
				<a-descriptions-item label="运输方式" >
				    {{ detail.transportWay }}
				</a-descriptions-item>
				<a-descriptions-item label="出货仓库">
				    {{ detail.storageName ||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="出库仓库">
				    {{ detail.outcomeStorage||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="经销商名称" >
				    {{ detail.sellerName }}
				</a-descriptions-item>
				<a-descriptions-item label="所属地县">
				    {{ detail.city ||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="所属乡镇">
				    {{ detail.district||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="总件数" >
				    {{ detail.productGoodsCount }}
				</a-descriptions-item>
				<a-descriptions-item label="总重量(吨)">
				    {{ detail.totalWeight ||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="总金额">
				    {{ detail.totalOutcomeMoney.toLocaleString()+'元'||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="区域主管联系电话" >
				    {{ detail.areaDirectorPhone }}
				</a-descriptions-item>
				<a-descriptions-item label="分库人" >
				    {{ detail.fenKuPerson }}
				</a-descriptions-item>
				<a-descriptions-item label="分库时间">
				    {{ detail.subTreasuryTime ||'--'}}
				</a-descriptions-item>
				<a-descriptions-item label="运输提交时间">
				    {{ detail.transportSubmitTime||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="本单产生积分" >
				    {{ detail.integralAll }}
				</a-descriptions-item>
			</a-descriptions>
			<a-divider orientation="left">调拨货物</a-divider>
			<div class="li_item">
				<div class="arpproval_title">
					<span>品牌名称</span>
					<span>产品名称</span>
					<span>购货数量</span>
					<span>折扣数量</span>
					<span>促销数量</span>
					<span>批次</span>
					<span>出厂价</span>
					<span>价格折扣</span>
					<span>结算金额</span>
					<span>是否计算积分</span>
					<!-- <span>空桶品质</span> -->
				</div>
				<div class="arpproval_title arpproval_title2" v-for="item in detail.outcomeGoodsInfoList">
					<span>{{item.brandName}}</span>
                    <span>{{item.goodsName}}</span>
					<span>{{item.goodsCount}}</span>
					<span> {{item.discount}}</span>
					<span>{{item.promotionCount}}</span>
					<span>{{item.batchNo}}</span>
					<span>{{item.originalPrice.toLocaleString()||0}}元</span>
					<span>{{item.priceRebate}}</span>
					<span>{{item.totalPayment.toLocaleString()||0}}元</span>
					<span>{{item.integral?'是':'否'}}</span>
					<!-- <span>{{item.priceRebate}}</span > -->
				</div>
			</div>
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
		</div>
	</div>
</template>

<script>
	import LookImages from "@/components/LookImages";
	import {
		outbound_order_detail
	} from '@/api/push.js'
	import Avatar from "@/components/small/Avatar";
	export default {
		components: {
			LookImages,
			Avatar
		},
		data() {
			return {
				detail: {
					emptyBarrelInfo: {},
					outcomeGoodsInfoList: [],
                    notify_record:[],
					emptyBarrelInfo: []
				}
			}
		},
		created() {
			this.get_info()
		},
		methods: {
			get_info() {
				outbound_order_detail({
					data: {
						id: this.$route.query.id,
					}
				}).then(res => {
					this.detail = res.data.data
					// this.detail.sign_url = ' https://crm-img.runtian.cn/crm-dev.waakuu.com/manage/20220827/c1b9514cc8fff091ead7533811738703/1661584102595.9k=.jpg?x-oss-process=style/visit'
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.time_line {
		max-width: 800px;
	}

	.avatar_ul {
		display: flex;
		flex-wrap: wrap;

		.avatar {
			margin: 16px 10px 0;
			width: 80px;
		}
	}

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.li_item {
		width: 100%;
		.title_item {
			width: 80px;
			text-align: left;
			display: inline-block;
			color: #000;
		}

		margin-bottom: 30px;
	}

	.arpproval_title {
		padding: 15px 0;
		text-align: center;
		background: rgba(245, 245, 245, 1);
		font-size: 14px;
		span {
			width: 9%;
			display: inline-block;
		}

		.department_appr {
			width: 18%;
		}
	}

	.arpproval_title1 {
		height: auto;
		width: 300px;
		background: none;
		border: 1px solid rgba(245, 245, 245, 1);
		.department_appr {
			width: 50%;
		}
	}
    
	.arpproval_title2 {
		background: #fff;
		border: 1px solid rgba(245, 245, 245, 1);
	}

	.go_see {
		color: #1890ff;
	}

	.ant-descriptions-item-label {
		width: 100px;
	}
    
</style>
